<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="search = null"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onExportData="exportData"
    ></list-template>
  </div>
</template>

<script>
export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [
        {
          "prop":"username",
          "placeholder":"搜索测评者姓名"
        },
        {
          "prop":"position_name",
          "placeholder":"搜索应聘岗位"
        },
        {
          "tag":"select",
          "prop":"school_id",
          "placeholder":"筛选应聘校区",
          "options":[
          ]
        },
        {
          "tag":"select",
          "prop":"gender",
          "placeholder":"筛选性别",
          "options":[
            {
              label:"女",
              value:1
            },
            {
              label:"男",
              value:2
            }
          ]
        },
        {
          "tag":"select",
          "prop":"personality_result",
          "placeholder":"筛选霍兰德匹配结果",
          "options":[
            {
              label:"合格",
              value:1
            },
            {
              label:"不合格",
              value:2
            }
          ]
        },
        {
          "tag":"select",
          "prop":"psychotherapy_result",
          "placeholder":"筛选心理治疗量表结果",
          // 1：合格，2：重点评估，3：不合格
          "options":[
              {
                label:"合格",
                value:1
              },
              {
                label:"重点评估",
                value:2
              },
              {
                label:"不合格",
                value:3
              }
          ]
        },
        {
          "tag": "daterange",
          "prop": "finish_time",
          "placeholder": "筛选心理治疗量表结果",
          start: '开始日期',
          end: '结束日期'
        }
      ],
      // 表格配置
      tableConfig: [
        {
          "prop":"name",
          "label":"测评名称"
        },
        {
          "prop":"username",
          "label":"测评者姓名"
        },
        {
          "prop":"gender",
          "label":"性别"
        },
        {
          "prop":"school_name",
          "label":"应聘校区"
        },
        {
          "prop":"position_name",
          "label":"应聘岗位"
        },
        {
          "prop":"phone",
          "label":"联系方式"
        },
        {
          "prop":"personality_result",
          "label":"霍兰德匹配结果"
        },
        {
          "prop":"psychotherapy_result",
          "label":"心理治疗量表结果"
        },
        {
          "handle":true,
          "label":"操作"
        }
      ],
      // 表格中的数据
      tableData: [],
    }
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.$_axios2.get("api/survey/survey/schools",{logic:1}).then(res => {
      this.$utils.updateOptions.call(this,'prop',"school_id",this.$tools.jsonToArray(res.data,{key:"value",val:"label"}))
    })
    this.getData();
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      let params = JSON.parse(JSON.stringify(search))
      if (!!params && params.finish_time) console.log(params.finish_time = params.finish_time.join('~'))
      this.loading = true;
      this.$_axios2.get('api/survey/survey/pc-list', {params: {...params, page}, logic: 1}).then(res => {
        let {list,page} = res.data
        this.tableData = list
        let {total} = page;
        this.total = total
      }).finally(() => this.loading = false);
    },
    // 新增按钮
    onAdd() {
      this.$router.push('./add')
    },
    // 表格后面操作被点击
    tableHandle(row, handle) {
      const {type} = handle
      if (type === "view") {
        this.$router.push({path: './details', query: {id: row.id}})
      }
    },
    // 导出
    exportData(){
      let params = JSON.parse(JSON.stringify(this.search))
      if (!!params && params.finish_time) console.log(params.finish_time = params.finish_time.join('~'))
      this.$_axios2.get("api/survey/survey/pc-export",{
        params: {...params},
      }).then(res=>{
        this.$tools.download("职业人格自测结果", res.data);
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
